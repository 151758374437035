import React, { useState, useEffect, useCallback } from 'react';
import config from './config';

const REFRESH_DELAY = 2000;

const TokenInput = () => {
  const [token, setToken] = useState('');

  const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          fetch(`${config.API_BASE_URL}/update-secret`, {
              method: 'POST',
              body: token
          });
          setToken('');
      }
  };

  return (
      <input
          type="text"
          id="token-input"
          placeholder="PredictIt Token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          onKeyDown={handleKeyPress}
      />
  );
};

const TradeHistory = ({ history }) => (
  <ul className="trade-history">
    {history.map((trade, index) => (
      <li key={index} className="bg-gray-100 p-2 rounded">
        <span className="font-bold">&gt; {new Date(trade.timestamp).toLocaleTimeString()}</span>: {trade.result.error ? (
            <span style={{color: "red"}}>{trade.result.error}</span>
          ) : (
            <span style={{color: "green"}}>{`Bought ${trade.result.predictit.shares} ${trade.result.predictit.bet} shares for $${trade.result.predictit.price} on Predictit, and ${trade.result.polymarket.shares} ${trade.result.polymarket.bet} shares for $${trade.result.polymarket.price} on Polymarket, for a total of $${(trade.result.predictit.price * trade.result.predictit.shares + trade.result.polymarket.price * trade.result.polymarket.shares).toFixed(2)}`}</span>
          )
        }
      </li>
    ))}
  </ul>
);

const App = () => {
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState('');
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState('');
  const [quote, setQuote] = useState(null);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [secondsSinceRefresh, setSecondsSinceRefresh] = useState(0);
  const [password, setPassword] = useState('');
  const [tradeHistory, setTradeHistory] = useState([]);
  const [error, setError] = useState('');
  const [isQuoteFetching, setIsQuoteFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchQuote = useCallback(async () => {
    if (!selectedMarket || !selectedContract || isQuoteFetching) return;
    
    setIsQuoteFetching(true);
    try {
      const response = await fetch(`${config.API_BASE_URL}/markets/${selectedMarket}/contracts/${selectedContract}`);
      const data = await response.json();
      setQuote(data);
      setLastRefresh(new Date());
      setSecondsSinceRefresh(0);
    } catch (error) {
      setError('Failed to fetch quote');
    } finally {
      setIsQuoteFetching(false);
    }
  }, [selectedMarket, selectedContract, isQuoteFetching]);

  useEffect(() => {
    fetchMarkets();
  }, []);

  useEffect(() => {
    if (selectedMarket) {
      fetchContracts(selectedMarket);
    }
  }, [selectedMarket]);

  useEffect(() => {
    let isMounted = true;
    let lastFetchTime = Date.now();
  
    const fetchQuoteWithDelay = async () => {
      while (isMounted && selectedContract) {
        const now = Date.now();
        const timeSinceLastFetch = now - lastFetchTime;
  
        if (timeSinceLastFetch >= REFRESH_DELAY) {
          await fetchQuote(); // Wait for the fetch to complete
          lastFetchTime = Date.now(); // Update last fetch time
        } else {
          const waitTime = REFRESH_DELAY - timeSinceLastFetch;
          await new Promise(resolve => setTimeout(resolve, waitTime)); // Wait for remaining time
        }
      }
    };
  
    fetchQuoteWithDelay(); // Start the fetch process
  
    return () => {
      isMounted = false; // Cleanup if the component unmounts
    };
  }, [selectedContract, fetchQuote]);
  

  useEffect(() => {
    if (lastRefresh) {
      const updateInterval = setInterval(() => {
        setSecondsSinceRefresh(Math.round((new Date() - lastRefresh) / 1000));
      }, 1000); // Update every second
      return () => clearInterval(updateInterval);
    }
  }, [lastRefresh]);

  const fetchMarkets = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/markets`);
      const data = await response.json();
      setMarkets(data);
    } catch (error) {
      setError('Failed to fetch markets');
    }
  };

  const fetchContracts = async (market) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/markets/${market}/contracts`);
      const data = await response.json();
      setContracts(data);
      setSelectedContract('');
    } catch (error) {
      setError('Failed to fetch contracts');
    }
  };

  const executeTrade = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.API_BASE_URL}/execute`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quote,
          password,
        }),
      });
      const result = await response.json();
      if (response.ok) {
        setTradeHistory([{ timestamp: new Date(), result }, ...tradeHistory]);
      } else {
        throw result.Message;
      }
    } catch (error) {
      setTradeHistory([{ timestamp: new Date(), result: { error } }, ...tradeHistory]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Trading System</h1>
      
      {error && (
        <div className="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      
      <div className="space-y-4">
        <div>
          <label className="block mb-2">Select Market:</label>
          <select
            className="w-full p-2 border rounded"
            value={selectedMarket}
            onChange={(e) => setSelectedMarket(e.target.value)}
          >
            <option value="">Select a market</option>
            {markets.map((market) => (
              <option key={market} value={market}>
                {market}
              </option>
            ))}
          </select>
        </div>

        {selectedMarket && (
          <div>
            <label className="block mb-2">Select Contract:</label>
            <select
              className="w-full p-2 border rounded"
              value={selectedContract}
              onChange={(e) => setSelectedContract(e.target.value)}
            >
              <option value="">Select a contract</option>
              {contracts.map((contract) => (
                <option key={contract} value={contract}>
                  {contract}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedContract && !quote && <div className="spinner"></div>}

        {quote && (
          <div className="quote">
            <p>Total Price: {quote.total_price}</p>
            <p>Available Shares: {quote.available_shares}</p>
            <p><i>Last refreshed {secondsSinceRefresh} seconds ago</i></p>
          </div>
        )}

        {quote && (
          <div>
            <input
              type="password"
              className="w-full p-2 border rounded mb-2"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {
              loading ? (
                <div className="spinner"></div>
              ) : (
                <button
                  className={`w-full p-2 rounded bg-blue-500 hover:bg-blue-600 text-white`}
                  onClick={executeTrade}
                  disabled={loading}
                >
                  Execute Trade
                </button>
              )
            }
          </div>
        )}

        {(quote && tradeHistory.length) ? <TradeHistory history={tradeHistory} /> : undefined}

        <TokenInput />
      </div>
    </div>
  );
};

export default App;
